body {  
  background-color: #8C8C8C;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */  
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: white;

  text-align: center;
  margin: 0 20px;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.text {
  display: flex;
  flex-direction: column;

  height: 250px;

}

.stores {
}

.stores img {
  height: 50px;
  width: auto;
}

.stores img:hover {
  cursor: pointer;
}

.footer {  
  font-size: calc(10px + 1vmin);
}

a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}

.animated-cursor::after {
  content: '|';
  animation: cursor 1.1s infinite step-start;
}